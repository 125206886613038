import React from 'react';
import { useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Img from 'gatsby-image';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import ArticleTile from '../../components/ArticleTile/ArticleTile';
import Alert from '../../components/Alert/Alert';

const RegActionsPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			chargesImg: file(relativePath: { eq: "chargesTileCropped02.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			convictionsImg: file(relativePath: { eq: "convictionsTileCropped.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			ordersImg: file(relativePath: { eq: "ordersTileCropped.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			commiteesImg: file(relativePath: { eq: "commiteesTileCropped.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			header={
				<span className='bold'>
					<FormattedMessage id='nav.regActivites' />
				</span>
			}
			subheader={<FormattedMessage id='nav.licensingComp' />}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.regActivitiesDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.regActivites' })}
			/>
			<Container>
				{intl.locale === 'fr' && (
					<Row>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}
				<Row align='stretch'>
					<Col lg={4} md={6}>
						<ArticleTile
							id='charges'
							image={<Img fluid={data.chargesImg.childImageSharp.fluid} alt='' />}
							linkPath='/licensing-compliance/regulatory-activites-enforcement/charges-and-convictions/'
							title='Charges'
							buttonTxt='View'
						>
							<h3 className='underline'>Recent Court Charges &amp; Convictions</h3>
						</ArticleTile>
					</Col>

					<Col lg={4} md={6}>
						<ArticleTile
							id='orders'
							image={<Img fluid={data.ordersImg.childImageSharp.fluid} alt='' />}
							title='Notices &amp; Orders'
							linkPath='/licensing-compliance/regulatory-activites-enforcement/notices-and-orders/'
							buttonTxt='View'
						>
							<h3 className='underline'>Recent Registrar's Actions &amp; Other Orders</h3>
						</ArticleTile>
					</Col>

					<Col lg={4} md={6}>
						<ArticleTile
							id='committees'
							image={<Img fluid={data.commiteesImg.childImageSharp.fluid} alt='' />}
							title='Discipline &amp; Appeals Committees Decisions'
							linkPath='/licensing-compliance/regulatory-activites-enforcement/disclipline-appeals/'
							buttonTxt='View'
						>
							<h3 className='underline'>Discipline &amp; Appeals Committees Proceedings &amp; Orders</h3>
						</ArticleTile>
					</Col>

					{/* <Col lg={3} md={6}>
            <ArticleTile
              id='committees'
              image={
                <Img fluid={data.commiteesImg.childImageSharp.fluid} alt='' />
              }
              title='Discipline &amp; Appeals Committees Decisions'
              disabled
              buttonTxt='View'
            >
              <h3 className='underline'>
                
                Discipline &amp; Appeals Committees Decisions

              </h3>
              <p>
                <i>Will be posted when available</i>
              </p>
            </ArticleTile>
          </Col> */}
				</Row>
			</Container>
		</Layout>
	);
};

export default RegActionsPage;
